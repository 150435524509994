import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <p>
                    Tim Stark 2020
                </p>
            </div>
        </div>
    )
}
